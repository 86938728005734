import React from 'react';
import Layout from '../components/layout';
import {css} from '@emotion/react';
import styled from '@emotion/styled';

import IconsImg from '../components/IconsImg';


const ResellerHeader = styled.div`
    padding:1rem;
    text-align:center;
    padding:5rem 10% 1rem 10%;
    display:flex;
    justify-content:center;
    h1{
        background-color:#22222250;
        width:100%;
        border-radius:5px;
        padding: 2rem 0;
        color:var(--s1color);
        font-family:QuubNormal, sans-serif;
        letter-spacing:2px;
    }
`;
const CountryBox = styled.li`
    padding:1rem;
    text-align:center; 
    background-color:#22222250;
    border-radius:3px;
    color:#ccc;
    ul{
        margin-top:1rem;
        display:flex;
        justify-content:center;
        li{
            margin:0 .5rem;
        }
    }
`;

export default function Resellers() {

    const {FacebookIcon,InstagramIcon,WhatsAppIcon} = IconsImg();

    return (
        <Layout>
            <div>
                <ResellerHeader>
                    <h1>Distribuidores Autorizados</h1>
                </ResellerHeader>
                <div>
                    <ul css={css`
                        display:grid;
                        grid-template-columns:repeat(2, 1fr);
                        grid-gap:1rem;
                        padding:1rem 10%;
                    `}>
                        <CountryBox>
                            <div>
                                <h2>Venezuela</h2>
                                <span>+58 4121099414</span>
                            </div>
                            <ul>
                                <li><a href='https://api.whatsapp.com/send?phone=584121099414&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL Venezuela Whatsapp' /> </a></li>
                            </ul>
                        </CountryBox>
                        <CountryBox>
                            <div>
                                <h2>Colombia</h2>
                                <span>+57 3202456784</span>
                            </div>
                            <ul>
                                <li><a href='https://api.whatsapp.com/send?phone=573202456784&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL Colombia Whatsapp' /> </a></li>
                                <li><a href='https://www.facebook.com/Sglseriegol/' target='_blank' rel="noreferrer"><img src={FacebookIcon} alt='SGL Colombia Facebook' /></a></li>
                                
                            </ul>
                        </CountryBox>
                        <CountryBox>
                            <div>
                                <h2>Brasil</h2>
                                <span>+55 9281374600</span>
                            </div>
                            <ul>
                                <li><a href='https://api.whatsapp.com/send?phone=559281374600&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL Brasil Whatsapp' /> </a></li>
                            </ul>
                            
                        </CountryBox>

                        <CountryBox>
                            <div>
                                <h2>Ecuador</h2>
                                <span>+59 3960050197</span>
                            </div>
                            <ul>
                                <li><a href='https://api.whatsapp.com/send?phone=593960050197&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL Ecuador Whatsapp'/> </a></li>
                                <li><a href='https://www.facebook.com/stlabecuador/' target='_blank' rel="noreferrer"><img src={FacebookIcon} alt='SGL Colombia Facebook' /></a></li>
                            </ul>

                        </CountryBox>

                        <CountryBox>
                            <div>
                                <h2>Aruba</h2>
                                <span>+297 7474221</span>
                            </div>
                            <ul>
                                <li><a href='https://api.whatsapp.com/send?phone=2977474221&text=Hola,%20necesito%20informaci%C3%B3n%20sobre%20sus%20productos.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL Aruba Whatsapp'/> </a></li>
                                <li><a href='https://www.facebook.com/darwin.guerreromosquera' target='_blank' rel="noreferrer"><img src={FacebookIcon} alt='SGL Aruba Facebook' /></a></li>
                                <a href='https://www.instagram.com/invites/contact/?i=s9nrap7wjfvp&utm_content=mp918q' target='_blank' rel="noreferrer"> <img src={InstagramIcon} alt='SGL Aruba Instagram' /> </a>    
                            </ul>

                        </CountryBox>
                    </ul>
                </div>

                <div css={css`
                        display:flex;
                        flex-direction:column;
                        padding:3rem 10% 1rem 10%;
                        align-items:center;
                        p{
                            margin-bottom:1rem;
                        }
                    `}
                >
                    <h3>¿Quieres ser Distribuidor?</h3>
                    <p>Escribenos por cualquiera de los siguientes medios y nos pondremos en contacto contigo.</p>
                    <div>
                        <a href='https://api.whatsapp.com/send?phone=573202456784&text=Hola,%20me%20interesa%20ser%20distribuidor%20de%20SGL.' target="_blank" rel="noreferrer"> <img src={WhatsAppIcon} alt='SGL WhatsApp' /> </a>
                        <a href='https://www.facebook.com/profile.php?id=100019604204995' target="_blank" rel="noreferrer"> <img src={FacebookIcon} alt='SGL Facebook' /> </a>
                        <a href='https://www.instagram.com/sglseriegold/' target='_blank' rel="noreferrer"> <img src={InstagramIcon} alt='SGL Instagram' /> </a>    
                    </div>
                </div>
            </div>
        </Layout>
    )
}
